
h1 {
    margin-bottom: 50px;
}

li a {
    text-decoration: none;
}

.links {
    margin-top: 50px;


}

@media only screen and (min-width: 800px) {
    .links{
        display: flex; 
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        margin-right: 30px;
        margin-left: 3%;
    }

    

}

.links div {
    background-color: #0172ff;
    width: 400px;
    height: 200px;
    margin-right: 30px;
    border-radius: 10px;
    text-decoration: none;
    margin-bottom: 30px;
    text-align: center;
    color: white;
}

.links h3 {
    padding-top: 50px;
}

@media only screen and (max-width: 1100px) {
    .links div {
        width: 100%;
    }
    .links a {
        width: 90%;
    }
}

.links a {
    text-decoration: none;
}

.links div:hover {
    color: silver;
}

/* @media only screen and (min-width: 769px) {

    .resources-container {
        padding-left: 40px;
        padding-top: 30px;
    }

} */


.understand-your-bible {
    margin-left: auto;
    margin-right: auto;
    background-color: #00c1ff;
    width: 100%;
}

@media only screen and (min-width: 800px) {
    .understand-your-bible {
        display: flex;
    }
}

@media only screen and (max-width: 800px) {
    .uyb-description {
        padding-left: 10%;
    }
    .understand-your-bible button {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 50px;
    }
}

.understand-your-bible button {
    padding: 20px;
    border-radius: 15px;
    margin-top: 20px;
    cursor: pointer;
}

.understand-your-bible button:hover {
    color: blue;
}

.uyb-description {
    width: 90%;
    padding-right: 5%;
}

.uyb-description ul li {
    list-style-type: square;
    line-height: 50px;
}

.uyb-description .uyb-header {
    font-size: 28px;
    line-height: 40px;
}

@media only screen and (min-width: 800px) {
    .understand-your-bible img {
        width: 70%;
    }
}

@media only screen and (max-width: 800px) {
    .understand-your-bible img {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

.special-videos {
    padding-left: 30px;
}

.special-videos p {
    padding-bottom: 20px;
    font-weight: bold;
}

.special-videos .headline {
    font-size: 23px;
    font-weight: normal;
}