h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #0f343a
}

.homepage-container {
    /* width: 50%; */
}

@media only screen and (max-width: 769px) {
    .service-information {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 769px) {
    .service-information {
        width: 60%;
    }

    .service-information {
        margin-left: 50px;
    }
}

.service-information h3 {
    font-size: 23px;
    margin-bottom: 0px;
}

.service-information p {
    margin-top: 15px;
    font-size: 22px;
}

address {
    font-size: 22px;
    background-color: #0072ff;
    width: 250px;
    border-radius: 5px;
    padding: 14px;
    color: white;
}

.google-map {
    margin-top: 30px;
    margin-bottom: 30px;
}

.who-are-we {
    margin-left: 50px;
    margin-right: 30px;
}

.who-are-we h2 {
    margin-left: 0;
}

.who-are-we p {
    font-size: 24px;
    line-height: 40px;
}

@media only screen and (min-width: 800px) {
    .who-are-we {
        width: 60%;
    }
}

.welcome {
    color: #0074ff;
    padding-bottom: 10px;
}

@media only screen and (min-width: 800px) {
    .hero {
        background-image: url('../assets/redditch1.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 500px;
        margin-bottom: 50px;
    }
}

.where {
    margin-left: 50px;
}