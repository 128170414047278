.footer-container {
    background: #00c6ff;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0072ff, #00c6ff);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    bottom: 0em;
    padding-bottom: 100px;
    padding-top: 60px;
    margin-top: 50px;
    
}

@media only screen and (min-width: 769px) {
    .footer-container {
        display: flex;
        padding-left: 50px;
    }
}



ul {
    margin-bottom: 0;
    margin-top: 0;
    display: block;
}

li {
    list-style-type: none;
    font-size: 24px;
    margin-bottom: 5px;
}

@media only screen and (min-width: 769px) {

.footer-container address {
    display: block;
    margin-left: 150px;
    margin-right: 150px;
    line-height: 30px;
}

}

@media only screen and (max-width: 769px) {
    .footer-container address {
        margin-top: 30px;
    }
}
.footer-container li a {
    color: white;
}

@media only screen and (max-width: 769px) {

    .footer-container address {
        line-height: 30px;
        display: block;
    }
    
}