.address-container {
    border: 2px solid black;
    margin-left: 15px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-radius: 10px;
    max-width: 1000px;
}



@media only screen and (min-width: 600px) {
    .address-container{ 
        display: flex;
        /* width: 50%; */
    }
}

@media only screen and (max-width: 600px) {
    .address-container {
        margin-right: 30px;
        padding-left: 30px;
    }
}

@media only screen and (min-width: 769px) {

    .address-sub-container {
        width: 50%;
    }
}

@media only screen and (max-width: 769px) {

    .address-sub-container {
        /* position: relative; */
        /* display: block; */
    }
}

.address-sub-container p {
    margin-bottom: 20px;
    margin-right: 40px;
}

.calendar-container {
    padding-top: 15px;
    min-width: 150px;
    margin-right:50px;
    /* height: 200px; */
    /* position: static; */
}

@media only screen and (min-width: 769px) {
    .calendar-container {
        margin-top:0px;
        /* position: static; */
    }
}

@media only screen and (max-width: 600px) {
    .calendar-container {
        float: left;
        margin-bottom: 30px;
    }
}
.calendar {
    background-image: url('../assets/calendar-blank-news.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 150px;
    height: 150px;
}

.calendar .day {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0px;
    padding-top: 50px;
    position: static;
}

.calendar .month {
    text-align: center;
    margin-top: 10px;
}

.address-sub-container .row {
    display: flex;
}

@media only screen and (max-width: 600px) {
    .address-sub-container .row {
        display: block;
    }
}

.address-sub-container .row p {
    display: inline-flex;
}
.address-sub-container .row p:first-of-type {
    width: 25%;
}