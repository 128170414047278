input {
    display: block;
    margin-bottom: 20px;
    width: 300px;
    height: 40px;
}

.submit {
    /* padding-top: 30px; */
    width: 200px;
    font-size: 27px;
}


input[type="textarea"] {
    height: 200px;
}

input[type="submit"] {
    margin-top: 30px;
}

@media only screen and (min-width: 500px) {
    .contact-form-container {
        padding-left: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .contact-form-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}


p {
    max-width: 100%;
    line-height: 27px;
}