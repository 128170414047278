@media only screen and (min-width: 769px) {
    .beliefs-container {
        width:80%;
        padding-left: 50px;
    }
}

@media only screen and (max-width: 769px) {
    .beliefs-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.beliefs-container h1 {
    margin-top: 50px;
}

.beliefs-container p {
    font-size: 24px;
    line-height: 37px;
    margin-bottom: 50px;
}

.beliefs-container h1 {
    margin-left: 3px;
}

.beliefs-container ul {
    padding-top: 20px;
    padding-left: 10px;
    margin-bottom: 100px;
}

.beliefs-container li {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
}

.beliefs-container li:nth-of-type(odd) {
    background-color: #0ac4fe;
}

.gospel-video {
    width: 100%;
    min-height: 500px;
}

.gospel-video-container {
    padding-top: 30px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}