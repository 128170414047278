.header-container h1 {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-top: 0;
    color: white;
}

nav li {
    display: inline;
    margin-right: 80px;
    font-size: 25px;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 10px;
}

nav li:hover {
    border-bottom: 1px solid white;
    transition-duration: 0.4s;
}

nav li a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

nav {
    /* margin-bottom: 50px; */
    
}

ul {
    padding-left: 30px;
}

@media only screen and (max-width: 800px) {
    nav li a{
        background-color: black;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 3px;
        color: white;
        width: 200px;
        text-align: center;
        border-radius: 5px;
        margin-top: -30px;

    }
}

@media only screen and (max-width: 800px) {
    nav li a:hover{
        color: black;
        background-color: white;
    }
}

.menu-button {
    margin-left: 30px;
    font-size: 25px;
    border: 1px solid black;
    width: 100px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.header-container{
    padding-top: 30px;
    padding-bottom: 30px;
    background: #00c6ff;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0072ff, #00c6ff);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}