.videos-container {
    display: flex;
    flex-wrap: wrap;
}

.video-container {
    /* margin-right: 20px; */
    margin-bottom: 10px;

}
@media only screen and (min-width: 700px) {
    .video-container {
        margin-right: 20px;
    }

    .youtube-player {
        width: 560px;
        height: 315px;
    }
}

@media only screen and (max-width: 700px) {
    .youtube-player {
        width: 100%;
        min-height: 280px;
    }
    .video-container {
        width: 100%;
    }
}